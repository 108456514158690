import { writable } from "svelte/store";
import isEqualWith from "lodash/isEqualWith";
export function view(store, viewFn = (value) => value, diffFn, initialValue) {
    let current = initialValue;
    const proxy = writable(current);
    function handler(next) {
        const next_value = viewFn(next);
        if (!isEqualWith(next_value, current, diffFn)) {
            current = next_value;
            proxy.set(current);
        }
    }
    store.subscribe(handler);
    return proxy;
}
