import { writable } from "svelte/store";
const EMPTY_QUERY = {};
const TEST_QUERY = {
    programme: {
        id: 14,
        name: "Psychology BSc",
        df: 42,
        tf: 42,
        relevance: 42,
    },
    categories: [
        {
            id: 41,
            name: "brain and nervous system disorders",
            parent_id: 5,
            relevance: 9.88838950049907,
            df: 405,
            tf: 199,
        },
    ],
    tutors: [],
    topics: [],
    clients: [],
};
export const query = writable(EMPTY_QUERY);
