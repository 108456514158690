// TODO: set api base url from environment parameter?
// const API_BASE = "http://localhost:3000/";
const API_BASE = "https://steers-api.home.karel-kroeze.nl/";
async function getResource(path, query) {
    const url = new URL(path, API_BASE);
    console.log({ resource: path, url: url.toString(), query });
    return await fetch(url, {
        method: "POST",
        body: JSON.stringify(query),
        headers: { "Content-Type": "application/json" },
    }).then((result) => result.json());
}
export async function getCategories(query) {
    return getResource("categories", query);
}
export async function getProgrammes(query) {
    return getResource("programmes", query);
}
export async function getClients(query) {
    return getResource("clients", query);
}
export async function getTutors(query) {
    return getResource("tutors", query);
}
export async function getTopics(query) {
    return getResource("topics", query);
}
export async function getEssays(query) {
    return getResource("essays", query);
}
